.sectionHeader {
  display: flex;
  align-items: center;
  gap: 2.4rem;
  margin-bottom: 2.4rem;
}

.title {
  font-size: var(--text-xl);
  font-weight: 500;
  text-align: end;

  span {
    color: var(--brand);
  }

  @media (max-width: 768px) {
    font-size: var(--text-lg);
  }
}

.line {
  width: 100%;
  height: 1px;
  background: var(--text);
  opacity: 0.3;
}
