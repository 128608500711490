.links {
  display: flex;
  gap: 1.6rem;
  padding-top: 10px;
  padding-bottom: 10px;

  a {
    opacity: 0.5;
    position: relative;
    z-index: 20;
  }

  a:hover {
    color: var(--brand);
    opacity: 1;
  }
}
