.home {
  position: relative;
  height: 100%; /* Ensure the home container takes up the full height */
}
.mainContent {
  margin-left: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}
