.standardButton {
  outline: none;
  border: none;
  cursor: pointer;

  padding: 1.2rem 2.4rem;

  background: var(--brand);
  color: var(--background-dark);
  font-size: var(--text-sm);
  border-radius: 4px;

  position: relative;
  z-index: 20;

  overflow: hidden;

  transition: 0.2s opacity;
}

.standardButton:hover {
  opacity: 0.9;
}
