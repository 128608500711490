.contactWrapper {
  max-width: 700px;
  margin: auto;
  border-radius: 1.2rem;
}

.contactTitle {
  font-size: var(--text-2xl);
  text-align: center;
  font-weight: 500;
  line-height: 1;

  span {
    color: var(--brand);
  }

  @media (max-width: 768px) {
    font-size: var(--text-xl);
  }
}

.contactCopy {
  text-align: center;
  font-weight: 200;
  margin: 2.4rem 0;
}

.contactForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
}

.inputField {
  width: 50%;
  background-color: var(--background);
  max-width: 300px;
  border: none;
  border-bottom: 2px solid var(--brand);
  padding: 0.8rem;
  font-size: var(--text-md);
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-bottom-color: var(--brand);
  }
}

.submitButton {
  background-color: var(--brand);
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: var(--text-md);
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--background-dark);
  }
}

.thankYouMessage {
  text-align: center;
  font-size: var(--text-md);
  margin-top: 2rem;
}
