:root {
  font-size: 62.5%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  --text-xs: 1.6rem;
  --text-sm: 1.8rem;
  --text-md: 2.2rem;
  --text-lg: 3.6rem;
  --text-xl: 5.6rem;
  --text-2xl: 9.6rem;

  --background: #dad9d2;
  --bg-opaque: rgba(197, 197, 197, 0.25);
  --background-light: #e6e6e0;
  --background-dark: #adaca3;

  --text: #000;
  --brand: #aca4a1;
}

.theme-1 {
  --background: #dad9d2;
  --bg-opaque: rgba(197, 197, 197, 0.25);
  --background-light: #e6e6e0;
  --background-dark: #adaca3;

  --text: #000;
  --brand: #aca4a1;
}

.theme-2 {
  --background: #000;
  --bg-opaque: rgba(197, 197, 197, 0.25);
  --background-light: #000;
  --background-dark: #000;

  --text: #fff;
  --brand: #aca4a1;
}

/* In src/App.css or src/index.css */
@font-face {
  font-family: 'Kalice';
  src: url('./assets/fonts/Kalice-Trial-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kalice';
  src: url('./assets/fonts/Kalice-Trial-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Kalice';
  src: url('./assets/fonts/Kalice-Trial-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Kalice';
  src: url('./assets/fonts/Kalice-Trial-BoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Kalice';
  src: url('./assets/fonts/Kalice-Trial-Black.otf') format('opentype');
  font-weight: 900; /* or any weight you prefer */
  font-style: normal;
}

@font-face {
  font-family: 'Kalice';
  src: url('./assets/fonts/Kalice-Trial-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-color: var(--background)-light;
}

*::selection {
  background: var(--brand);
  color: var(--background);
}

html,
body {
  max-width: 100vw;
  scroll-behavior: smooth;
  font-size: var(--text-sm);
  font-family: Kalice, sans-serif;
}

body {
  color: var(--text);
  background: var(--background);
}

a {
  color: inherit;
  text-decoration: none;
}

.section-wrapper {
  position: relative;
  z-index: 10;
  max-width: 1150px;
  margin: auto;
  padding: 9.6rem;
  overflow: hidden;
  min-height: 80vh;
}

@media (max-width: 768px) {
  .section-wrapper {
    padding: 7.2rem 2.4rem;
  }
}

.chip {
  font-size: var(--text-xs);
  background: var(--background-light);
  padding: 0.2rem 0.8rem;
  border-radius: 999px;
}
