.heading {
  background: var(--background);
  position: sticky;
  top: 0;
  right: 0;
  z-index: 20;
  
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between logo and links */

  height: calc(45px + 3.6rem);
  padding: 0 3.6rem;
  font-size: var(--text-md);
  font-weight: bold;
  border-bottom: 1px solid var(--text);

  /* Links to section headings */
  a {
    margin-left: 2.4rem; /* Adds space between each link */
    font-size: var(--text-sm);
    font-weight: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    border-bottom: 2px solid transparent; /* Updated to use bottom border for underline effect */
    transition: 0.2s all ease-in-out;

    @media (max-width: 768px) {
      display: none;
    }

    /* Pseudo-element for the growing underline */
    &::before {
      content: '';
      position: absolute;
      bottom: -2px; /* Position the underline */
      left: 0;
      height: 2px;
      width: 0; /* Start with width of 0 */
      background-color: var(--brand);
      border-bottom: 1px solid transparent; 
      transition: width 0.5s ease-in-out; /* Smooth transition for width */
    }
  }

  a:hover {
    opacity: 1;
    background: var(--background);
    &::before {
      width: 100%;
    }
  }

  .selected {
    opacity: 1;
    background: var(--background);
    &::before {
      width: 100%;
    }
  }
}

.heading a.instalilyLogoContainer {
  opacity: 0.9;

  @media (max-width: 768px) {
    display: block;
    padding-top: 8px;
  }
}

.logo {
  flex-shrink: 0;
  font-size: var(--text-md);
  font-weight: 500;
  line-height: 1;

  width: 45px;
  height: 45px;

  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-light);
  border-radius: 4px;

  margin-right: auto; /* Logo stays on the left */
  cursor: pointer;

  span {
    font-size: var(--text-md);
    font-weight: 500;
    line-height: 1;
    color: var(--brand);
  }
}

.instalilyLogo {
  height: 35px;
}

.heading::-webkit-scrollbar {
  display: none;
}
