.hero {
  position: relative;
  display: flex;
  justify-content: center; 
  align-items: center; 
  min-height: calc(100vh - 50px);
  margin-bottom: 0; 
  width: 100%;

  @media (max-width: 768px) {
    min-height: calc(100vh - 60px); /* Adjust for mobile header height */
  }
}

.copyWrapper {
  position: relative;
  z-index: 10;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0; 
}

.glitchImageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.title {
  font-size: var(--text-2xl);
  font-weight: medium;
  line-height: 1.1;
  font-style: italic;

  span {
    color: var(--brand);
  }

  @media (max-width: 768px) {
    font-size: var(--text-xl); 
    text-align: center;
  }
}

.subTitle {
  font-size: var(--text-lg);
  line-height: 1.1;
  margin-top: 1.6rem;
  font-weight: 200;

  span {
    font-weight: bold;
    color: var(--brand);
  }
}

.aboutCopy {
  margin: 2.4rem 0;
  max-width: 700px;
  font-weight: 200;
}
